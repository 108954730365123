.delete-button {
    height: 40px;
    width: 40px;
    background: white;
    color: #FF0000;
    border-radius: 16%;
    border: none; 
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.delete-button-popup .leaflet-popup-content-wrapper {
    background: none !important;
    box-shadow: none !important;
}

.delete-button-popup .leaflet-popup-tip-container {
    display: none;
}

.delete-button-popup .leaflet-popup-close-button {
    display: none;
}

.resultPin {
    background: green;
    padding: 3px !important;
    opacity: 1 !important;
}

.resultPin>h4 {
    color: #424242;
    font-weight: normal;
    margin: 0 !important;

}

.resultPin>h5 {
    color: grey;
    font-weight: normal;
    margin: 0 !important;

}

.sub-market-label {

    color: #3a3939;
    border: none !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
    color: white;
    font-weight: 900;
    text-shadow:-2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
}

.show-labels {
    font-size: 14px !important;

}

.zoom-mid {
    font-size: 9px !important;
}

.zoom-max {
    font-size: 13px;
}
