:root {
  --white: #fff;
  --red800: #c62828;
  --red500: #f44336;
  --grey50: #fafafa;
  --grey100: #f5f5f5;
  --grey200: #eee;
  --grey300: #e0e0e0;
  --grey400: #bdbdbd;
  --grey500: #9e9e9e;
  --grey600: #757575;
  --grey700: #616161;
  --grey800: #424242;
  --grey900: #212121;

  --blue50: #e3f2fd;
  --blue500: #2196f3;
  --blue100: #bbdefb;
  --blue200: #90caf9;
  --blue300: #64b5f6;
  --blue400: #42a5f5;
  --blue500: #2196f3;
  --blue600: #1e88e5;
  --blue700: #1976d2;
  --blue800: #1565c0;
  --blue900: #0d47a1;

  --green50: #e8f5e9;
  --green100: #c8e6c9;
  --green200: #a5d6a7;
  --green300: #81c784;
  --green400: #66bb6a;
  --green500: #4caf50;
  --green600: #43a047;
  --green700: #388e3c;
  --green800: #2e7d32;
  --green900: #1b5e20;

  --spacing: 10px;
  --spacing-lg: 25px;
  --spacing-xl: 50px;

  --colliersBlue: #25408f;
  --colliersYellow: #ffd400;
  --colliersCyan: #0c9ed9;
  --colliersRed: #ed1a35;
}
